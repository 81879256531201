import { navigate } from "gatsby";
import React, { useEffect } from "react";
import IndexLayout from "../layouts";

const FeatureUpdates = () => {
  useEffect(() => {
    navigate("/");
  }, []);
  return <IndexLayout />;
};

export default FeatureUpdates;
